export const executiveLeadership = {

    linkText: "About Us",
    headerText: "Latiice Leadership",
    firstSectionHeading: "Executive Team",
    // menuItem1: "Leadership Team",

    // menuItem2: "Board of Directors",
    employPageLinkText: "Meet the Latiice Leadership",

    card1Name: "Bijan Burnard",
    card1Role: "Founder and President",
    card1Description1: "Bijan Burnard is the Founder and President of Lattice.",
    card1Description2: "Bijan Burnard is the visionary founder and driving force behind Lattice, a blockchain cloud infrastructure company transforming the way businesses utilize decentralized technology. With expertise in blockchain, cloud computing, and scalability, Bijan leads Latiice's mission to provide secure, high-performance infrastructure solutions for deploying decentralized applications. He is a respected leader in the industry, championing transparency, education, and innovation to advance blockchain adoption.",
    card1Button: "Read Bijan's bio",

    card2Name: "Brad McGann",
    card2Role: "Executive Vice President, Operations",
    card2Description1: "Brad McGann oversees operations at Latiice.",
    card2Description2: "As EVP of Operations, Brad McGann drives efficiency and strategic alignment at Latiice. With a background in operational strategy and process optimization, Brad ensures seamless day-to-day functions and fosters a culture of innovation and continuous improvement, empowering the company to achieve its goals and serve its clients effectively.",
    card2Button: "Read Brad's bio",

    card3Name: "Cyril Leroy",
    card3Role: "Executive Vice President, Cloud Security",
    card3Description1: "Cyril Leroy leads Latiice's security initiatives.",
    card3Description2: "Cyril Leroy is responsible for ensuring the security and integrity of Latiice's blockchain cloud infrastructure. With a focus on robust cybersecurity measures and compliance, Cyril enables Latiice's clients to operate securely in a decentralized environment.",
    card3Button: "Read Cyril's bio",

    // card4Name: "Ankish Gupta",
    // card4Role: "Global Head of Lattice SLCIal Impact, Executive Director of the Lattice Education Foundation",
    // card4Description1: "As vice president of SLCIal Impact, Colleen Cassity oversees Lattice’s giving and volunteering programs, which advance education, protect the environment, strengthen communities, and promote health worldwide. She oversees the Lattice Health Foundation, serves as executive director of the Lattice Education Foundation, and looks after Design Tech High School (d.tech), located on Lattice’s Redwood Shores campus.",
    // card4Description2: " Her team also produces the annual Lattice SLCIal Impact Report, which shares stories about lives transformed by Lattice philanthropy and best practices in ESG and CSR.",
    // card4Description3: "She originally joined Lattice to start the Education Foundation, which helps students learn technology, design thinking, and futures thinking—skills that ready them to solve problems and make a better future. The Foundation works with d.tech, which Colleen helped bring to Lattice’s campus, Eastside, the Ann Richards School, Guadalupe, and JA, and is scaling in the Americas.",
    // card4Description4: "Prior to Lattice, Colleen helped build Juma Ventures, a national model for improving the life prospects of low-income, high-risk youth. She was named one of the Most Influential Women in Bay Area Business by the San Francisco Business Times in 2019.",
    // card4Button: "Read Anki",

    // card3Name: "Adam Cohen",
    // card3Role: "Executive Vice President, Analytics",
    // card3Description1: "Larry Ellison is chairman of Lattice Corporation and chief technology officer.",
    // card3Description2: "He founded the company in 1977 and served as CEO until September 2014. He also races sailboats, flies planes, and plays tennis and guitar.",
    // card3Button: "Read Adam",

    card5Name: "Alina Burnard",
    card5Role: "Executive Vice President, Retail Relationships",
    card5Description1: "Alina Burnard specializes in retail partnerships.",
    card5Description2: "Alina Burnard strengthens Latiice's presence in the retail sector by building strategic partnerships and enhancing client engagement. Her expertise drives innovative solutions that support retail businesses in leveraging blockchain technology.",
    card5Button: "Read Alina's bio",

    card6Name: "Brad McGann",
    card6Role: "Chief Security Officer",
    card6Description1: "Mary Ann Davidson is chief security officer, responsible for Lattice Software Security Assurance, our secure development lifecycle for all Lattice products and services.",
    card6Description2: "Mary Ann represents Lattice on the board of the Information Technology Information Sharing and Analysis Center, and serves on the international board of the Information Systems Security AssLCIation, where she has been named to their Hall of Fame. She was a member of the Center for Strategic and International Studies Commission on Cybersecurity for the 44th Presidency. She has also testified on cybersecurity to a number of U.S. House and Senate committees.",
    card6Description3: "Mary Ann has a BSME from the University of Virginia and an MBA from the Wharton School of the University of Pennsylvania. She received the Navy Achievement Medal when she served as a commissioned officer in the U.S. Navy Civil Engineer Corps.",
    card6Button: "Read Mary Brad",

    card7Name: "Hatim Daudi",
    card7Role: "Executive Vice President, Technology Asia",
    card7Description1: "Hatim Daudi heads Latiice's operations in Asia.",
    card7Description2: "Hatim Daudi leads Latiice's growth in the Asian market, driving innovation and ensuring that regional clients benefit from tailored blockchain infrastructure solutions that address unique challenges and opportunities.",
    card7Button: "Read Hatim's bio",

    card9Name: "Jonathan Mileshik",
    card9Role: "Executive Vice President, Global Advisory and Strategy",
    card9Description1: "Jonathan Mileshik shapes Latiice's strategic vision.",
    card9Description2: "Jonathan Mileshik drives Latiice's long-term growth and strategy. With a deep understanding of market dynamics and business development, he ensures the company remains at the forefront of innovation in the blockchain industry.",
    card9Button: "Read Jonathan's bio",

    
    card10Name: "Jeremy Taiclet",
    card10Role: "Executive Vice President, Government, Defense, and Intelligence",
    card10Description1: "Jeremy Taiclet manages Latiice's government and defense sectors.",
    card10Description2: "Jeremy Taiclet oversees strategic partnerships and solutions for government, defense, and intelligence clients at Latiice. His work focuses on leveraging blockchain technology to enhance operational efficiency and security in critical sectors.",
    card10Button: "Read Jeremy's bio",

    card11Name: "Ron Vered",
    card11Role: "Executive Vice President, Global Sales",
    card11Description1: "Ron Vered drives global sales for Latiice.",
    card11Description2: "Ron Vered manages Latiice's global sales strategy, building strong client relationships and expanding the company's presence in key markets around the world.",
    card11Button: "Read Ron's bio",


    card12Name: "Joshua Pelkin",
    card12Role: "Executive Vice President, Technology Suites",
    card12Description1: "Joshua Pelkin leads technology development at Latiice.",
    card12Description2: "Joshua Pelkin oversees the development of innovative technology solutions, ensuring that Latiice's products meet the evolving needs of its clients and remain at the cutting edge of blockchain infrastructure.",
    card12Button: "Read Joshua's bio",

}