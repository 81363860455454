import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../src/image/27.png";
// import flag from "../src/image/flg-us.svg";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JS bundle with Popper.js
import "./App.css";
import { navbarContent } from "./Content/eng_content";

function NavBar() {


  // flag code 


  const [currentCountry, setCurrentCountry] = useState("us");


  useEffect(() => {
    async function fetchUserCountry() {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        setCurrentCountry(data.country_code.toLowerCase());
      } catch (error) {
        console.error("Failed to fetch user location:", error);
      }
    }
    fetchUserCountry();
  }, []);


  const handleCountryChange = (countryCode) => {
    setCurrentCountry(countryCode);
    setShowModal(false);
  };





  const [activeSection, setActiveSection] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Ref to detect clicks outside dropdown
  const dropdownRef = useRef(null);
  const navbarRef = useRef(null);
  const modalRef = useRef(null);

  const handleNavClick = (section) => {
    if (activeSection === section && isDropdownVisible) {
      setDropdownVisible(false);
    } else {
      setActiveSection(section);
      setDropdownVisible(true);
    }
  };

  const toggleDiv = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && navbarRef.current) {
        // Check if the click was outside the dropdown and navbar
        if (
          !dropdownRef.current.contains(event.target) &&
          !navbarRef.current.contains(event.target)
        ) {
          setDropdownVisible(false); // Close dropdown when clicking outside
        }
      }
    };

    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Render the dropdown content dynamically based on the active section
  const renderDropdownContent = () => {
    if (!activeSection) return null;

    const sectionContent =
      navbarContent.dropdownContent[activeSection.toLowerCase()];
    if (!sectionContent) return null;

    return (
      <div
        className={`dropdown-content ${isDropdownVisible ? "visible" : ""}`}
        ref={dropdownRef}
      >
        <div className="dropdown-info">
          <h2>{sectionContent.title}</h2>
          {sectionContent.sections.map((section, index) => (
            <div key={index} className="product mt-3">
              <div className="listwrap">
                <ul className="lattice-list">
                  <li>
                    <strong>{section.heading}</strong>
                    <div className="grid-links">
                      <ul>
                        {section.links.map((link, idx) => (
                          <li key={idx}>
                            <a href={link.href}>{link.text}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  {/* Render subLinks if they exist */}
                  {section.subLinks &&
                    Object.keys(section.subLinks).map((key) => (
                      <li key={key}>
                        <strong>{key}</strong>
                        <div className="grid-links">
                          <ul>
                            {section.subLinks[key].map((subLink, subIdx) => (
                              <li key={subIdx}>
                                <a href={subLink.href}>{subLink.text}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    ))}
                  {/* Add divider if not the last section */}
                  {index < sectionContent.sections.length - 1 && (
                    <hr className="dropdownborder" />
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleFlagClick = () => {
    setShowModal(true); // Open the modal
  };

  const handleOutsideClick = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !navbarRef.current.contains(e.target)
    ) {
      setShowModal(false); // Close modal when clicking outside
    }
  };

  // Add event listener for clicks outside the modal
  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showModal]);

  return (
    <>
      <nav ref={navbarRef} className="navbar navbar-expand-lg custom-navbar">
        <div className="container-fluids">
          {/* Logo (keeping the image as is) */}
          <Link to="/">
            <img src={logo} alt="latice" className="navbar-logo" />
          </Link>

          <div className="visible0">
            <div className="d-flex align-items-center gap-2">
              {/* Search Icon */}
              <button
                className="search-icon-button"
                onClick={() => setShowSearch(!showSearch)}
                aria-label="Toggle search bar"
                style={{
                  border: "none",
                  background: "none",
                  padding: "0",
                  cursor: "pointer",
                }}
              >
                {/* Magnifying Glass SVG */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path d="M15,15l6,6M17,10a7,7,0,1,1-7-7A7,7,0,0,1,17,10Z"></path>
                </svg>
              </button>

              {/* Search Bar */}
              {showSearch && (
                <form className="d-flex search-bar-container" role="search">
                  <input
                    className="form-control me-2 search-bar"
                    type="search"
                    placeholder="Search "
                    aria-label="Search"
                  />

                  {/* SVG Arrow to Close Search Bar */}
                  <button
                    className="search-close-button"
                    type="button"
                    onClick={() => setShowSearch(false)}
                    aria-label="Close search bar"
                    style={{
                      border: "none",
                      background: "none",
                      padding: "0",
                      cursor: "pointer",
                    }}
                  >
                    {/* Left Arrow SVG (close the search bar) */}
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 4l-4 4 4 4"
                        stroke="#3A3631"
                        strokeWidth="1.5"
                        fill="none"
                      />
                    </svg>
                  </button>
                </form>
              )}

              {/* Flag Logo */}
              <img
                src={`https://flagcdn.com/w320/${currentCountry}.png`}
                alt="Flag"
                className="flag-logo"
                onClick={handleFlagClick}
              />
            </div>

            {/* Contact button */}



            <Link to="/contact" className="btn contact-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="contact-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8l7.894 7.894a1 1 0 0 0 1.414 0L21 8M5 19h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2z"
                />
              </svg>
              <span>{navbarContent.contact}</span>
            </Link>

            {/* View Accounts Button */}
            <button
              className={`btn account-btn`}
              onClick={toggleDiv}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="account-icon"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zM12 12c-3.314 0-6 2.686-6 6v1h12v-1c0-3.314-2.686-6-6-6z"
                />
              </svg>
              <span>{navbarContent.viewAccounts}</span>
            </button>
          </div>

          {/* Dropdown Menu */}
          {isActive && (
            <div className="account-dropdown">
              {/* Back Button */}
              <button
                className="accback-btn"
                onClick={() => {
                  setIsActive(false); // Close dropdown
                }}
              >
                Back
              </button>

              {/* Dropdown Content */}
              <div className="section">
                <h3>{navbarContent.latticeCloud}</h3>
                <button
                  className="acc-btn acc-btn-1"
                  onClick={() => {
                    setIsActive(false);

                  }}
                >
                  <Link to="/sign-in">{navbarContent.signInCloud}</Link>
                </button>
                <button
                  className="acc-btn acc-btn-2"
                  onClick={() => {
                    setIsActive(false);

                  }}
                >
                  <Link
                    to="/create-account"
                    style={{ textDecoration: "none", color: "inherit" }}>
                    {navbarContent.signUpCloud}
                  </Link>

                </button>
              </div>
              <hr />
              <div className="section">
                <h3>Lattice Cloud</h3>
                <button
                  className="acc-btn acc-btn-3"
                  onClick={() => {
                    setIsActive(false); // Close dropdown
                    // Add your navigation or other logic here
                  }}
                >
                  <Link to="/sign-in">{navbarContent.signIn}</Link>
                </button>
                <button
                  className="acc-btn acc-btn-4"
                  onClick={() => {
                    setIsActive(false); // Close dropdown
                    // Logic for account creation
                  }}
                >
                  {" "}
                  <Link
                    to="/create-account"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {navbarContent.createAccount}
                  </Link>
                </button>
              </div>
            </div>
          )}

          {/* Toggler for mobile view */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar items */}
          <div
            className="collapse navbar-collapse navbar-items"
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

            <li
                className={`nav-item ${activeSection === "Products" && isDropdownVisible
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleNavClick("Products")}
                >
                  {navbarContent.products}
                </Link>
              </li>

              
              {/* <li
                className={`nav-item ${activeSection === "Industries" && isDropdownVisible ? "active" : ""
                  }`}
              >
                <Link className="nav-link" to="#" onClick={() => handleNavClick("Industries")}>
                  {navbarContent.industries}
                </Link>
              </li> */}

              <li
                className={`nav-item ${activeSection === "Resources" && isDropdownVisible
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleNavClick("Resources")}
                >
                  {navbarContent.resources}
                </Link>
              </li>



              <li
                className={`nav-item ${activeSection === "Customers" && isDropdownVisible
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleNavClick("Customers")}
                >
                  {navbarContent.customers}
                </Link>
              </li>

              <li
                className={`nav-item ${activeSection === "Partners" && isDropdownVisible
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleNavClick("Partners")}
                >
                  {navbarContent.partners}
                </Link>
              </li>


              <li
                className={`nav-item ${activeSection === "Developers" && isDropdownVisible
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleNavClick("Developers")}
                >
                  {navbarContent.developers}
                </Link>
              </li>


              <li
                className={`nav-item ${activeSection === "Company" && isDropdownVisible
                  ? "active"
                  : ""
                  }`}
              >
                <Link
                  className="nav-link"
                  to="#"
                  onClick={() => handleNavClick("Company")}
                >
                  {navbarContent.company}
                </Link>
              </li>


            </ul>
          </div>

          {/* Dropdown Content */}
          {renderDropdownContent()}
        </div>
      </nav>

      {showModal && (
        <div className="country-selector-overlay">
          <button
            className="country-btn-close"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
            x
          </button>
          <div className="country-selector-modal" ref={modalRef}>
            <h5>See Lattice.com in your country/region</h5>
            <div class="country-selector-list">


              <p>Americas</p>

              <a href="#argentina" onClick={() => handleCountryChange("ar")}>Argentina</a>
              <a href="#belize" onClick={() => handleCountryChange("bz")}>Belize</a>
              <a href="#brasil" onClick={() => handleCountryChange("br")}>Brasil</a>
              <a href="#canada-english" onClick={() => handleCountryChange("ca")}>Canada - English</a>
              <a href="#canada-francais" onClick={() => handleCountryChange("ca")}>Canada - Français</a>
              <a href="#chile" onClick={() => handleCountryChange("cl")}>Chile</a>
              <a href="#colombia" onClick={() => handleCountryChange("co")}>Colombia</a>
              <a href="#costa-rica" onClick={() => handleCountryChange("cr")}>Costa Rica</a>
              <a href="#mexico" onClick={() => handleCountryChange("mx")}>México</a>
              <a href="#peru" onClick={() => handleCountryChange("pe")}>Perú</a>
              <a href="#united-states" onClick={() => handleCountryChange("us")}>United States</a>
              <a href="#uruguay" onClick={() => handleCountryChange("uy")}>Uruguay</a>
              <a href="#america-latina">América Latina</a>


              <p>Asia</p>

              <a href="#australia" onClick={() => handleCountryChange("au")}>Australia</a>
              <a href="#bangladesh" onClick={() => handleCountryChange("bd")}>Bangladesh</a>
              <a href="#china" onClick={() => handleCountryChange("cn")}>中国</a>
              <a href="#hong-kong" onClick={() => handleCountryChange("hk")}>Hong Kong SAR, PRC</a>
              <a href="#india" onClick={() => handleCountryChange("in")}>India</a>
              <a href="#indonesia" onClick={() => handleCountryChange("id")}>Indonesia</a>
              <a href="#japan" onClick={() => handleCountryChange("jp")}>日本</a>
              <a href="#korea" onClick={() => handleCountryChange("kr")}>대한민국</a>
              <a href="#malaysia" onClick={() => handleCountryChange("my")}>Malaysia</a>
              <a href="#new-zealand" onClick={() => handleCountryChange("nz")}>New Zealand</a>
              <a href="#pakistan" onClick={() => handleCountryChange("pk")}>Pakistan</a>
              <a href="#philippines" onClick={() => handleCountryChange("ph")}>Philippines</a>
              <a href="#singapore" onClick={() => handleCountryChange("sg")}>Singapore</a>
              <a href="#sri-lanka" onClick={() => handleCountryChange("lk")}>Sri Lanka</a>
              <a href="#thailand" onClick={() => handleCountryChange("th")}>Thailand</a>
              <a href="#vietnam" onClick={() => handleCountryChange("vn")}>Vietnam</a>
              <a href="#japac">JAPAC</a>
              <a href="#ASEAN">ASEAN</a>

              <p>Europe</p>

              <a href="#austria" onClick={() => handleCountryChange("at")}>Österreich</a>
              <a href="#belgium" onClick={() => handleCountryChange("be")}>Belgium</a>
              <a href="#bosnia-herzegovina" onClick={() => handleCountryChange("ba")}>Bosna i Hercegovina</a>
              <a href="#bulgaria" onClick={() => handleCountryChange("bg")}>България</a>
              <a href="#croatia" onClick={() => handleCountryChange("hr")}>Hrvatska</a>
              <a href="#czech-republic" onClick={() => handleCountryChange("cz")}>Czech Republic</a>
              <a href="#denmark" onClick={() => handleCountryChange("dk")}>Denmark</a>
              <a href="#germany" onClick={() => handleCountryChange("de")}>Deutschland</a>
              <a href="#estonia" onClick={() => handleCountryChange("ee")}>Eesti</a>
              <a href="#finland" onClick={() => handleCountryChange("fi")}>Suomi</a>
              <a href="#france" onClick={() => handleCountryChange("fr")}>France</a>
              <a href="#greece" onClick={() => handleCountryChange("gr")}>Ελλάδα</a>
              <a href="#hungary" onClick={() => handleCountryChange("hu")}>Hungary</a>
              <a href="#ireland" onClick={() => handleCountryChange("ie")}>Ireland</a>
              <a href="#italy" onClick={() => handleCountryChange("it")}>Italia</a>
              <a href="#latvia" onClick={() => handleCountryChange("lv")}>Latvija</a>
              <a href="#luxembourg" onClick={() => handleCountryChange("lu")}>Luxembourg</a>
              <a href="#moldova" onClick={() => handleCountryChange("md")}>Moldova</a>
              <a href="#netherlands" onClick={() => handleCountryChange("nl")}>Nederland</a>
              <a href="#norway" onClick={() => handleCountryChange("no")}>Norge</a>
              <a href="#poland" onClick={() => handleCountryChange("pl")}>Polska</a>
              <a href="#portugal" onClick={() => handleCountryChange("pt")}>Portugal</a>
              <a href="#romania" onClick={() => handleCountryChange("ro")}>Romania</a>
              <a href="#serbia" onClick={() => handleCountryChange("rs")}>Serbia</a>
              <a href="#slovenia" onClick={() => handleCountryChange("si")}>Slovenija</a>
              <a href="#slovakia" onClick={() => handleCountryChange("sk")}>Slovensko</a>
              <a href="#spain" onClick={() => handleCountryChange("es")}>España</a>
              <a href="#sweden" onClick={() => handleCountryChange("se")}>Sverige</a>
              <a href="#switzerland" onClick={() => handleCountryChange("ch")}>Schweiz</a>
              <a href="#switzerland-fr" onClick={() => handleCountryChange("ch")}>Suisse</a>
              <a href="#turkey" onClick={() => handleCountryChange("tr")}>Türkiye</a>
              <a href="#ukraine" onClick={() => handleCountryChange("ua")}>Україна</a>
              <a href="#united-kingdom" onClick={() => handleCountryChange("uk")}>United Kingdom</a>
              <a href="#other-europe" onClick={() => handleCountryChange("eu")}>Other Europe countries</a>


              <p>Middle East And Africa</p>

              <a href="#africa-english" onClick={() => handleCountryChange("afr-en")}>Africa - English</a>
              <a href="#africa-francais" onClick={() => handleCountryChange("afr-fr")}>Afrique - Français</a>
              <a href="#algeria" onClick={() => handleCountryChange("dz")}>الجزائر</a>
              <a href="#bahrain" onClick={() => handleCountryChange("bh")}>البحرين</a>
              <a href="#egypt" onClick={() => handleCountryChange("eg")}>مصر</a>
              <a href="#jordan" onClick={() => handleCountryChange("jo")}>الأردن</a>
              <a href="#kenya" onClick={() => handleCountryChange("ke")}>كينيا</a>
              <a href="#kuwait" onClick={() => handleCountryChange("kw")}>الكويت</a>
              <a href="#morocco" onClick={() => handleCountryChange("ma")}>المغرب</a>
              <a href="#nigeria" onClick={() => handleCountryChange("ng")}>نيجيريا</a>
              <a href="#qatar" onClick={() => handleCountryChange("qa")}>قطر</a>
              <a href="#saudi-arabia" onClick={() => handleCountryChange("sa")}>المملكة العربية السعودية</a>
              <a href="#senegal" onClick={() => handleCountryChange("sn")}>السنغال</a>
              <a href="#south-africa" onClick={() => handleCountryChange("za")}>جنوب أفريقيا</a>
              <a href="#uae" onClick={() => handleCountryChange("ae")}>الإمارات العربية المتحدة</a>
              <a href="#middle-east-region" onClick={() => handleCountryChange("me")}>الشرق الأوسط</a>
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default NavBar;
