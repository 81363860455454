import React, { useState, useRef } from 'react';
import './blog-page.css'
import { footerSections, cookieText } from '../Content/eng_content';
import { Link } from 'react-router-dom';
import { blogPage } from '../Content/English-Content/BlogCompany';
import svg1 from "../image/svg/rss_feed_24dp_E8EAED.svg"
import svg3 from "../image/svg/email_24dp_E8EAED.svg"


import img1 from '../image/BACKPACK BLOG.png'
import img2 from '../image/WOMAN BLOCK.png'
import img3 from '../image/CYBER VS BLOCKCHAIN.png'
import img4 from '../image/SOLUTIONS CYBER.png'
import svg2 from "../image/svg/search_24dp_E8EAED.svg"
import blog1 from '../image/BACKPACK BLOG.png'
import blog2 from '../image/WOMAN BLOCK.png'
import blog3 from '../image/CYBER VS BLOCKCHAIN.png'
import blog4 from '../image/SOLUTIONS CYBER.png'
import blog5 from '../image/ROCA SPONSOR.png'
import blog6 from '../image/study.png'
import blog7 from '../image/developer-img/blog7.avif'
import blog8 from '../image/developer-img/blog8.avif'
import section2Blog1 from '../image/developer-img/16x9+2024AI.avif'
import section2Blog2 from '../image/developer-img/16x9+FinanceDemo.avif'
import section2Blog3 from '../image/developer-img/16x9+2025BestPractices.avif'
import section2Blog4 from '../image/developer-img/16x9ERPDollarSafe.avif'
import section2Blog5 from '../image/developer-img/Recruiting_16x9.avif'
import section2Blog6 from '../image/developer-img/16x9Integration.avif'
import section2Blog7 from '../image/developer-img/16x9-AI-agents.avif'
import section2Blog8 from '../image/developer-img/16x9Direction.avif'
import Latticelogo from '../image/lattice-logo-img.svg';



function BlogPage() {


    const [openSections, setOpenSections] = useState({});

    const toggleDropdown = (section) => {
        setOpenSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };


    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentIndex2, setCurrentIndex2] = useState(0); // Index for blogs2


    // Array of blog cards with unique content
    const blogs = [
        {
            id: 1,
            image: blog1,
            title: blogPage.thirdSectionArticle1Heading,
            link: "https://latticelabs.io/developments-news-1.html",
            // duration: blogPage.thirdSectionArticle1Duration,
        },
        {
            id: 2,
            image: blog2,
            title: blogPage.thirdSectionArticle2Heading,
            link: "https://latticelabs.io/updates-news-1.html",
            // duration: blogPage.thirdSectionArticle2Duration,
        },
        {
            id: 3,
            image: blog3,
            title: blogPage.thirdSectionArticle3Heading,
            link: "https://latticelabs.io/news-details-3.html",
            // duration: blogPage.thirdSectionArticle3Duration,
        },
        {
            id: 4,
            image: blog4,
            title: blogPage.thirdSectionArticle4Heading,
            link: "https://latticelabs.io/news-details-4.html",
            // duration: blogPage.thirdSectionArticle4Duration,
        },
        {
            id: 5,
            image: blog5,
            title: blogPage.thirdSectionArticle5Heading,
            link: "https://latticelabs.io/news-details-5.html",
            // duration: blogPage.thirdSectionArticle5Duration,
        },
        {
            id: 6,
            image: blog6,
            title: blogPage.thirdSectionArticle6Heading,
            link: "https://latticelabs.io/news-details-6.html",
            // duration: blogPage.thirdSectionArticle6Duration,
        },
        // {
        //     id: 7,
        //     image: blog6,
        //     title: blogPage.thirdSectionArticle7Heading,
        //     link: "#",
        //     duration: blogPage.thirdSectionArticle7Duration,
        // },
        // {
        //     id: 8,
        //     image: blog5,
        //     title: blogPage.thirdSectionArticle8Heading,
        //     link: "#",
        //     duration: blogPage.thirdSectionArticle8Duration,
        // },
    ];


    // New array of blogs (blogs2)
    // const blogs2 = [
    //     { id: 1, image: section2Blog1, title: blogPage.fourthSectionArticle1Heading, link: "#", duration: blogPage.fourthSectionArticle1Duration },
    //     { id: 2, image: section2Blog2, title: blogPage.fourthSectionArticle2Heading, link: "#", duration: blogPage.fourthSectionArticle2Duration },
    //     { id: 3, image: section2Blog3, title: blogPage.fourthSectionArticle3Heading, link: "#", duration: blogPage.fourthSectionArticle3Duration },
    //     { id: 4, image: section2Blog4, title: blogPage.fourthSectionArticle4Heading, link: "#", duration: blogPage.fourthSectionArticle4Duration },
    //     { id: 5, image: section2Blog5, title: blogPage.fourthSectionArticle5Heading, link: "#", duration: blogPage.fourthSectionArticle5Duration },
    //     { id: 6, image: section2Blog6, title: blogPage.fourthSectionArticle6Heading, link: "#", duration: blogPage.fourthSectionArticle6Duration },
    //     { id: 7, image: section2Blog7, title: blogPage.fourthSectionArticle7Heading, link: "#", duration: blogPage.fourthSectionArticle7Duration },
    //     { id: 8, image: section2Blog8, title: blogPage.fourthSectionArticle8Heading, link: "#", duration: blogPage.fourthSectionArticle8Duration },
    // ];


    const handleNext = () => {
        if (currentIndex + 4 < blogs.length) {
            setCurrentIndex((prev) => prev + 4);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 4);
        }
    };

    // const handleNext2 = () => {
    //     if (currentIndex2 + 4 < blogs2.length) {
    //         setCurrentIndex2((prev) => prev + 4);
    //     }
    // };

    const handlePrev2 = () => {
        if (currentIndex2 > 0) {
            setCurrentIndex2((prev) => prev - 4);
        }
    };

    // cookies


    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleCookieClick = () => {
        setShowCookieModal(true);
        setIsLoading(true);


        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const handleAcceptAll = () => {
        console.log("Cookies Accepted");
        setShowCookieModal(false);
    };

    const handleDenyAll = () => {
        console.log("Cookies Denied");
        setShowCookieModal(false);
    };




    return (
        <>


            <div className="Lattice-blog-div">

                <div className="Lattice-blog-div-heading">

                    <h1 className="Lattice-blog-div-heading-h1">{blogPage.firstSectionHeader}</h1>

                    <p className="heading-below-para-of-blog">
                        {blogPage.firstSectionPara}
                    </p>

                </div>

                <div className="Lattice-blog-div-icon">

                    Follow: <img src={svg1} className="img-wifi"></img>

                </div>


            </div>


            <div className="blogs-container">

                <div className="upper-area-img">

                    <div className="upper-area-img-one">

                        <img src={img1} className="img-of-one-div-of-uai"></img>

                        <a href='https://latticelabs.io/developments-news-1.html' className="heading-of-img">{blogPage.secondSectionHeading}</a><br></br>

                        {/* <a href='https://latticelabs.io/developments-news-1.html' className="para-of-img1">{blogPage.secondSectionPara}</a> */}

                        {/* <p className="para-of-img-time">{blogPage.secondSectionDuration}</p> */}

                    </div>

                    <div className="upper-area-img-sec">

                        <div className="upper-area-img-sec-one-img">

                            <div className="img-one-uaisoi" > <img src={img2} className="img-one-uaisoi"></img></div>

                            <div className="img-content">

                                <a href='https://latticelabs.io/updates-news-1.html' className="one-link-heading">{blogPage.firstArticleHeading}</a><br></br>

                                <a className="para-of-img1">{blogPage.firstArticlePara}</a>

                                {/* <p  className="para-of-img-time">{blogPage.firstArticleDuration}</p> */}

                            </div>

                        </div>

                        <div className="upper-area-img-sec-one-img">

                            <div className="img-one-uaisoi" > <img src={img3} className="img-one-uaisoi"></img></div>

                            <div className="img-content">

                                <a className="one-link-heading">{blogPage.secondArticleHeading}</a><br></br>

                                <a className="para-of-img1">{blogPage.secondArticlePara}</a>

                                {/* <p className="para-of-img-time">{blogPage.secondArticleDuration}</p> */}

                            </div>


                        </div>

                        <div className="upper-area-img-sec-one-img">

                            <div className="img-one-uaisoi" > <img src={img4} className="img-one-uaisoi"></img></div>

                            <div className="img-content">

                                <a className="one-link-heading">{blogPage.thirdArticleHeading}</a><br></br>

                                <a className="para-of-img1">{blogPage.thirdArticlePara}</a>

                                {/* <p className="para-of-img-time">{blogPage.thirdArticleDuration}</p> */}

                            </div>

                        </div>

                    </div>

                </div>

            </div>


            <div className="search-wrapper-blog">

                <div className="search-area-of-blog">

                    <div className="sign-up-blog-1-div">

                        <h2 className="search-box-heading">{blogPage.searchBlogs}</h2>

                        <img src={svg2} className="search-icon-blog"></img>
                        <input
                            type="search"
                            placeholder="Enter search..."
                            className="search-input-of-blog"
                            required
                        />

                    </div>

                    <div className="border-between-blog"></div>


                    <div className="heading-and-side-btn-of-search">

                        <h2 className="search-box-heading">{blogPage.recieveBlogUpdates}</h2>

                        <a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}> <button className="search-btn-of-blog">{blogPage.subcribeToLatticeButton}</button> </a>

                    </div>


                </div>


            </div>

            <div className="blog-container-all">

                <div className="horizontal-line-of-blog"></div>

                <div className="fist-blog-of-container">
                    <div className="blog-card-all-container">
                        {/* Header Section */}
                        <div className="blog-card-all-header">
                            <h1 className="blog-card-all-heading">{blogPage.thirdSectionHeading}</h1>
                            <div className="blog-card-all-controls">
                                <a href="#" className="blog-card-all-see-all">
                                    {blogPage.seeAll}
                                </a>
                                <button
                                    className={`blog-card-all-arrow blog-card-all-prev ${currentIndex === 0 ? "disabled" : ""
                                        }`}
                                    onClick={handlePrev}
                                    disabled={currentIndex === 0}
                                >
                                    <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 4l-4 4 4 4" stroke="#3A3631" strokeWidth="1" fill="none" />
                                    </svg>
                                </button>
                                <button
                                    className={`blog-card-all-arrow blog-card-all-next ${currentIndex + 4 >= blogs.length ? "disabled" : ""
                                        }`}
                                    onClick={handleNext}
                                    disabled={currentIndex + 4 >= blogs.length}
                                >
                                    <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 4l4 4-4 4" stroke="#3A3631" strokeWidth="1" fill="none" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        {/* Slider Section */}
                        <div className="blog-card-all-slider">
                            <div
                                className="blog-card-all-track"
                                style={{
                                    transform: `translateX(-${currentIndex * 25}%)`, // 25% slide per 4 cards
                                }}
                            >
                                {blogs.map((blog) => (
                                    <div key={blog.id} className="blog-card-all-item">
                                        <img src={blog.image} alt={`Blog ${blog.id}`} className="blog-card-all-image" />
                                        <h3>{blog.title}</h3>
                                        <div className="blog-card-all-footer">
                                            <a href={blog.link} className="blog-card-all-link">
                                                {blogPage.readMore}
                                            </a>
                                            <span className="blog-card-all-divider">|</span>
                                            <span className="blog-card-all-duration">{blog.duration}</span>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="horizontal-line-of-blog2"></div>

            </div>


            {/* Blog Slider 2 (Using blogs2) */}




            <div className="last-div-of-blog">

                <h1 className="last-heading-of-blog">{blogPage.recieveBlogUpdates}</h1>

                <a href="/create-account" style={{ color: "inherit", textDecoration: "none" }}> <button className="last-blog-btns">{blogPage.subcribeToLatticeButton} <img src={svg3} className="last-img-of-blog"></img></button> </a>

            </div>






            <div className="footer">
                <div className="f_list">
                    {footerSections.map((section) => (
                        <div key={section.id} className="list-section">
                            <h3
                                className="footer-heading"
                                onClick={() => toggleDropdown(section.id)}
                            >
                                {section.heading}
                                <span
                                    className={`arrow ${openSections[section.id] ? 'rotate' : ''}`}
                                >
                                    &#8595;
                                </span>
                            </h3>
                            <div
                                className={`resource-list ${openSections[section.id] ? 'show' : ''
                                    }`}
                            >
                                <ul>
                                    {section.links.map((link, index) => (
                                        <li key={index}>
                                            {link.to.startsWith('/') ? (
                                                <Link to={link.to}>{link.text}</Link>
                                            ) : (
                                                <a href={link.to}>{link.text}</a>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">

                    <span className="footer-separator"> © 2024 Lattice</span> |

                    <span className="footer-separator"><Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> TermsofUseandPrivacy </Link></span>

                    <span className="footer-separator"
                        style={{ cursor: "pointer", color: "inherit" }}
                        onClick={handleCookieClick}> Cookie Preferences  </span>

                    <span className="footer-separator"> <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}> Ad Choices  </Link>  </span>

                    <span className="footer-separator"><Link to="/careers" style={{ textDecoration: 'none', color: 'inherit' }}> Careers </Link>  </span>
                </div>

            </div>


            {showCookieModal && (
                <div
                    className={`cookie-modal-overlay ${showCookieModal ? "modal-open" : "modal-closed"
                        }`}
                >
                    <div className="cookie-modal">

                        {isLoading ? (
                            <div className="loading-spinner">{cookieText.loading}</div>
                        ) : (
                            <div className="cookie-content">

                                <button className="close-btn-of-cookies" onClick={() => setShowCookieModal(false)}>
                                    &times;
                                </button>
                                <div className="heading-of-cookies-with-logo">

                                    <h3 className="heading-of-cookies">{cookieText.heading}</h3>

                                    <img src={Latticelogo} className="img-logo-of-cookies-header"></img>

                                </div>

                                <div className="div-2-heading-below-text-and-list">
                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph1}</p>

                                    <ul>
                                        {cookieText.listItems.map((item, index) => (
                                            <li key={index} className="div-2-cookies-list-text">
                                                <span className="bold-cookies-peferance">{item.title}</span> {item.description}
                                            </li>
                                        ))}
                                    </ul>

                                    <p className="div-2-para-below-heading-ofcookies">{cookieText.paragraph2}</p>

                                </div>

                                <div className="button-group">
                                    <button className="btn accept-btn" onClick={handleAcceptAll}>
                                        {cookieText.btn1}
                                    </button>
                                    <button className="btn deny-btn" onClick={handleDenyAll}>
                                        {cookieText.btn2}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    );
};

export default BlogPage;
